<template>
	<div>
		<el-card v-if="!showForm" class="add-card">
			<div slot="header" class="clearfix">
				<span>课程发布</span>
				<el-button
					@click="showForm = true"
					style="float: right; padding: 3px 0"
					type="text"
				>
					新增课程
				</el-button>
			</div>
			<div v-if="courseList.length > 0">
				<el-table :data="courseList" style="width: 100%">
					<el-table-column type="expand">
						<template slot-scope="props">
							<el-form
								label-position="left"
								inline
								class="demo-table-expand"
							>
								<el-form-item
									v-if="
										props.row.courseInfo.courseIntroduction
									"
									style="width: 100%"
									label="课程简介:"
								>
									<p>
										{{
											props.row.courseInfo
												.courseIntroduction
										}}
									</p>
								</el-form-item>
								<el-form-item
									v-if="props.row.courseInfo.courseName"
									label="课程名称:"
								>
									<span>{{
										props.row.courseInfo.courseName
									}}</span>
								</el-form-item>
								<el-form-item
									v-if="props.row.courseSite.siteName"
									label="场景:"
								>
									<span>{{
										props.row.courseSite.siteName
									}}</span>
								</el-form-item>
								<el-form-item
									v-if="props.row.teacherInfo.teacherName"
									label="授课教师:"
								>
									<span>{{
										props.row.teacherInfo.teacherName
									}}</span>
								</el-form-item>
								<el-form-item
									v-if="
										props.row.courseSite.siteContactPerson
									"
									label="课程联系人:"
								>
									<span>{{
										props.row.courseSite.siteContactPerson
									}}</span>
								</el-form-item>
								<el-form-item
									v-if="props.row.courseSite.siteContactPhone"
									label="联系电话:"
								>
									<span>{{
										props.row.courseSite.siteContactPhone
									}}</span>
								</el-form-item>
								<el-form-item
									v-if="props.row.courseRule.selectMaxNumber"
									label="容纳人数:"
								>
									<span>{{
										props.row.courseRule.selectMaxNumber
									}}</span>
								</el-form-item>
								<el-form-item
									v-if="props.row.typeDescription"
									label="课程类型:"
								>
									<span>{{ props.row.typeDescription }}</span>
								</el-form-item>
								<el-form-item
									v-if="props.row.courseInfo.courseLabel"
									label="课程标签:"
								>
									<span
										v-for="(dict, i) in $store.state
											.courseLabelDict"
										:key="i"
										><span
											v-if="
												dict.dictKey ==
													props.row.courseInfo
														.courseLabel
											"
											>{{ dict.dictValue }}</span
										></span
									>
								</el-form-item>
								<el-form-item
									v-if="props.row.courseInfo.courseLimitGrade"
									label="年级限制:"
								>
									<span
										>{{
											props.row.courseInfo
												.courseLimitGrade
										}}年级</span
									>
								</el-form-item>
								<el-form-item
									v-if="props.row.courseInfo.courseRoom"
									label="上课教室:"
								>
									<span>{{
										props.row.courseInfo.courseRoom
									}}</span>
								</el-form-item>
								<el-form-item
									style="width: 100%"
									v-if="
										props.row.courseInfoTime.length > 0 &&
											props.row.courseInfoTime[0]
												.timeType == 2
									"
									label="课程时间:"
									class="courseTimeList"
								>
									<div
										:style="{
											paddingLeft: '30px',
											display: 'flex',
											flexDirection: 'column',
										}"
									>
										<div
											v-for="(item, index) in props.row
												.courseInfoTime"
											:key="index"
										>
											<span style="margin-right: 5px"
												>
											<!--	第{{ item.round }}轮次第{{
													item.session
												}}节课:-->
												体验课：
											</span
											>
											<span style="margin-right: 20px">{{
												item.date
											}}</span>
											<span
												>{{ item.startTime }} 至
											</span>
											<span>{{ item.endTime }}</span>
										</div>
									</div>
								</el-form-item>
								<el-form-item
									style="width: 100%"
									v-if="
										props.row.courseInfoTime.length > 0 &&
											props.row.courseInfoTime[0]
												.timeType == 1
									"
									label="课程时间:"
								>
									<div
										v-for="(item, index) in props.row
											.courseInfoTime"
										:key="index"
									>
										<span>{{ item.startDay }}</span>
										<span> ~ </span>
										<span>{{ item.endDay }}</span>
										<span style="margin: 0 20px"
											>每周{{
												time[item.dayOfWeek - 1]
											}}</span
										>
										<span
											>{{ item.startTime }}
											至
										</span>
										<span>{{ item.endTime }}</span>
									</div>
								</el-form-item>
								<el-form-item
									v-if="props.row.courseInfo.courseDetail"
									label="课程详情:"
									style="width: 100%"
									class="courseDetail"
								>
									<div
										class="ql-editor"
										v-html="
											props.row.courseInfo.courseDetail
										"
									></div>
								</el-form-item>
							</el-form>
						</template>
					</el-table-column>
					<el-table-column
						label="课程名称"
						prop="courseInfo.courseName"
					>
					</el-table-column>
					<el-table-column
						label="课程场景"
						prop="courseSite.siteName"
					>
					</el-table-column>
					<el-table-column
						label="授课老师"
						width="110"
						prop="teacherInfo.teacherName"
					>
					</el-table-column>
					<el-table-column
						label="课程状态"
						width="110"
						prop="courseInfo.courseStatus"
						:formatter="courseStateFormat"
					>
					</el-table-column>
					<el-table-column label="操作" width="110">
						<template slot-scope="scope">
							<el-dropdown>
								<span class="el-dropdown-link">
									操作菜单<i
										class="el-icon-arrow-down el-icon--right"
									></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item
										:disabled="
											scope.row.courseInfo.courseStatus !=
												4 &&
												scope.row.courseInfo
													.courseStatus != 5
										"
										@click.native="
											handleEdit(scope.$index, scope.row)
										"
										><i class="el-icon-edit"></i
										>编辑</el-dropdown-item
									>
									<el-dropdown-item
										@click.native="
											handleCopy(scope.$index, scope.row)
										"
										><i class="el-icon-document-copy"></i
										>复制</el-dropdown-item
									>
									<el-dropdown-item
										:disabled="
											scope.row.courseInfo.courseStatus !=
												4 &&
												scope.row.courseInfo
													.courseStatus != 5
										"
										@click.native="
											handleDelete(
												scope.$index,
												scope.row
											)
										"
										><i class="el-icon-delete"></i
										>删除</el-dropdown-item
									>
									<el-dropdown-item
										:disabled="
											scope.row.courseInfo.courseStatus ==
												2 ||
												scope.row.courseInfo
													.courseStatus == 4 ||
												scope.row.courseInfo
													.courseStatus == 5 || scope.row.courseInfo
													.courseStatus == 6
										"
										@click.native="
											handleOffline(
												scope.$index,
												scope.row
											)
										"
										>课程下线</el-dropdown-item
									>
									<el-dropdown-item
										:disabled="
											scope.row.courseInfo.courseStatus !=
												4
										"
										@click.native="
											handleSubmit(
												scope.$index,
												scope.row
											)
										"
										>提交审核</el-dropdown-item
									>

									<el-dropdown-item
										:disabled="
											scope.row.courseInfo.courseStatus !=
												1 &&
												scope.row.courseInfo
													.courseStatus != 3 &&
												scope.row.courseInfo
													.courseStatus != 8
										"
										@click.native="
											handleLookStudent(
												scope.$index,
												scope.row
											)
										"
										>查看已预约学生</el-dropdown-item
									>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pages">
				<el-pagination
					v-if="total > size"
					@current-change="toggleCurrent"
					:current-page="current"
					background
					layout="prev, pager, next"
					:total="total"
					:page-size="size"
				>
				</el-pagination>
			</div>
			<el-dialog title="已预约学生名单" :visible.sync="showStudentList">
				<el-table
					size="mini"
					:data="studentList"
					align="center"
					style="width: 100%"
				>
					<el-table-column
						type="index"
						label="序号"
						width="50"
					></el-table-column>
					<el-table-column
						prop="studentName"
						label="姓名"
						width="120"
					>
					</el-table-column>
					<el-table-column label="性别" width="100">
						<template slot-scope="scope">
							<span>{{ scope.row.sex == 0 ? '女' : '男' }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="gradeName" label="年级" width="120">
					</el-table-column>
					<el-table-column prop="schoolName" label="学校">
					</el-table-column>
					<el-table-column
						prop="userTele"
						label="联系电话"
						width="150"
					>
					</el-table-column>
				</el-table>
				<el-button
					@click="downLoadExcel"
					v-if="studentList.length > 0"
					type="primary"
					size="small"
					style="margin-top: 15px"
					>下载名单</el-button
				>
			</el-dialog>
		</el-card>
		<AddCourse
			v-else-if="isTea && isSit && isDic"
			:isAdd="isAdd"
			:teacherList="teacherList"
			:courseSiteList="courseSiteList"
			:courseDictionaryList="courseDictionaryList"
			@changeShowForm="changeShowForm"
		/>
	</div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import AddCourse from './components/addCourse'
import moment from 'moment'
import {
	releaseCourseList_api,
	teacherList_api,
	courseSiteList_api,
	courseDictionaryList_api,
	reviewCourse_api,
	removeCourse_api,
	offlineCourse_api,
	getCourseStudents_api,
	copyCourse_api,
} from '@/api/user.js'
import { sessionGet } from '@/utils/local.js'
export default {
	components: {
		AddCourse,
	},
	data() {
		return {
            time:['一', '二', '三', '四', '五', '六', '日'],
			showStudentList: false, // 展示学生列表模态框
			courseList: [], // 发布的课程列表
			current: 1,
			size: 5,
			total: 0,
			showForm: false, // 是否展示新增课程的表单
			isAdd: true, // 是新增还是修改，true表示新增，false表示修改（控制是否回显数据）
			teacherList: [], // 授课教师列表
			isTea: false, // 教师列表数据是否准备好
			courseSiteList: [], // 课程场景列表
			isSit: false, // 场景数据是否准备就绪
			courseDictionaryList: [], // 课程分类列表
			isDic: false, // 课程分类数据是否准备就绪
			studentList: [], // 发布的课程已预约学生名单
			fileName: '预约学生名单',
		}
	},
	methods: {
		//课程状态字典翻译
		courseStateFormat(row, column) {
			var name = ''
			this.$store.state.courseStateDict.forEach(function(item, index) {
				if (row.courseInfo.courseStatus == Number(item.dictKey)) {
					name = item.dictValue
				}
			})
			return name
		},
		// 获取已发布课程列表
		async releaseCourseList() {
			let { code, data } = await releaseCourseList_api({
				createUser: sessionGet('userId'),
				size: this.size,
				current: this.current,
			})
			if (code === 200) {
				if (data.records.length == 0 && this.current > 1) {
					this.current--
					this.releaseCourseList()
				} else {
					data.records.forEach((item, index) => {
						if (
							item.courseInfoTime.length > 0 &&
							item.courseInfoTime[0].timeType == 2
						) {
							// 自定义伦次
							let courseTime = {}
							courseTime = {
								courseTimeType: item.courseInfoTime[0].timeType,
								roundsArr: [
									{
										courseTimeList: [
											{
												id: item.courseInfoTime[0].id,
												date:
													item.courseInfoTime[0].date,
												startTime:
													item.courseInfoTime[0]
														.startTime,
												endTime:
													item.courseInfoTime[0]
														.endTime,
											},
										],
									},
								],
								circulation: {
									date: '',
									day: 6,
									startTime: '',
									endTime: '',
								},
							}
							item.courseTime = courseTime
							let k = 0
							for (
								let i = 1;
								i < item.courseInfoTime.length;
								i++
							) {
								if (
									item.courseInfoTime[i].round ==
									item.courseInfoTime[i - 1].round
								) {
									courseTime.roundsArr[k].courseTimeList.push(
										{
											id: item.courseInfoTime[i].id,
											date: item.courseInfoTime[i].date,
											startTime:
												item.courseInfoTime[i]
													.startTime,
											endTime:
												item.courseInfoTime[i].endTime,
										}
									)
								} else {
									++k
									courseTime.roundsArr.push({
										courseTimeList: [
											{
												id: item.courseInfoTime[i].id,
												date:
													item.courseInfoTime[i].date,
												startTime:
													item.courseInfoTime[i]
														.startTime,
												endTime:
													item.courseInfoTime[i]
														.endTime,
											},
										],
									})
								}
								item = Object.assign({}, item, { courseTime })
							}
							// item.courseTime.roundsArr = JSON.parse(item.courseTimeList)
							// item.courseTime.roundsArr.forEach((val) => {
							// 	val.courseTimeList.forEach((v) => {
							// 		v.date = moment(v.date).format('yyyy-MM-DD')
							// 	})
							// })
						} else if (
							item.courseInfoTime.length > 0 &&
							item.courseInfoTime[0].timeType == 1
						) {
							let date = [],
								idCourseInfo,
								day,
								endTime,
								startTime
							idCourseInfo = item.courseInfoTime[0].id
							date[0] = item.courseInfoTime[0].startDay
							date[1] = item.courseInfoTime[0].endDay
							day = item.courseInfoTime[0].dayOfWeek
							startTime = item.courseInfoTime[0].startTime
							endTime = item.courseInfoTime[0].endTime
							// 	item.courseTime.circulation = JSON.parse(item.courseTimeList)
							// 	item.courseTime.circulation.date[0] = moment(
							// 		new Date(item.circulation.date[0])
							// 	).format('yyyy-MM-DD')
							// 	item.courseTime.circulation.date[1] = moment(
							// 		new Date(item.circulation.date[1])
							// 	).format('yyyy-MM-DD')
							// 	item.circulationDay = item.circulation.day
							// 	switch (item.circulation.day) {
							// 		case 1:
							// 			item.circulationDay = '每周一'
							// 			break
							// 		case 2:
							// 			item.circulationDay = '每周二'
							// 			break
							// 		case 3:
							// 			item.circulationDay = '每周三'
							// 			break
							// 		case 4:
							// 			item.circulationDay = '每周四'
							// 			break
							// 		case 5:
							// 			item.circulationDay = '每周五'
							// 			break
							// 		case 6:
							// 			item.circulationDay = '每周六'
							// 			break
							// 		case 7:
							// 			item.circulationDay = '每周日'
							// 			break
							// 		default:
							// 			item.circulationDay = '每周六'
							// 	}
							// 	item.courseTime.roundsArr = [
							// 		{
							// 			courseTimeList: [
							// 				{
							// 					date: '',
							// 					startTime: '',
							// 					endTime: '',
							// 				},
							// 			],
							// 		},
							// 	]
							let courseTime = {}
							courseTime = {
								courseTimeType: item.courseInfoTime[0].timeType,
								roundsArr: [
									{
										courseTimeList: [
											{
												date: '',
												startTime: '',
												endTime: '',
											},
										],
									},
								],
								circulation: {
									id: idCourseInfo,
									date: date,
									day: day,
									startTime: startTime,
									endTime: endTime,
								},
							}
							item.courseTime = courseTime
						}
                        else if (
							item.courseInfoTime.length == 0
						) {
                            let courseTime = {}
							courseTime = {
								courseTimeType: '1',
								roundsArr: [
									{
										courseTimeList: [
											{
												date: '',
												startTime: '',
												endTime: '',
											},
										],
									},
								],
								circulation: {
									date: '',
									day: 6,
									startTime: '',
									endTime: '',
								},
							}
							item.courseTime = courseTime
                        }
					})
					this.courseList = data.records
					// console.log('this.courseList:', this.courseList)
					this.total = data.total
				}
			}
		},
		// 切换页码
		toggleCurrent(current) {
			this.current = current
			this.releaseCourseList()
			document.documentElement.scrollTop = document.body.scrollTop = 0
		},
		// 新增课程与列表切换
		changeShowForm(bool) {
			this.showForm = bool
			if (!bool) this.isAdd = true // 展示列表的时候，将isAdd重置
			this.releaseCourseList()
		},
		// 获取授课教师列表
		async getTeacherList() {
			let { data, code } = await teacherList_api()
			if (code == 200) {
				this.teacherList = data
				this.isTea = true
			}
		},
		// 获取场景列表
		async getCourseSiteList() {
			let { data, code } = await courseSiteList_api()
			if (code == 200) {
				this.courseSiteList = data
				this.isSit = true
			}
		},
		// 获取课程分类列表
		async getCourseDictionaryList() {
			let { data, code } = await courseDictionaryList_api()
			if (code == 200) {
				this.courseDictionaryList = data
				this.isDic = true
			}
		},
		// 获取已预约学生名单
		async handleLookStudent(index, course) {
			this.fileName = course.courseName || '预约学生名单'
			let { code, data, msg } = await getCourseStudents_api({
				courseId: course.courseInfo.id,
				reserveStatus: 1,
			})
			if (code == 200) {
				this.studentList = data
				this.showStudentList = true
			}
		},
		// 下载已预约学生名单
		downLoadExcel() {
			let stuData = JSON.parse(JSON.stringify(this.studentList))
			let data = []
			stuData.forEach((item, index) => {
				data[index] = {
					studentName: item.studentName,
					sex: item.sex == 1 ? '男' : '女',
					gradeName: item.gradeName,
					schoolName: item.schoolName,
					userTele: item.userTele,
				}
			})
			let fileName = this.fileName
			//定义表头
			let str = `姓名,性别,年级,学校,联系电话\n`
			//增加\t为了不让表格显示科学计数法或者其他格式
			for (let i = 0; i < data.length; i++) {
				for (let item in data[i]) {
					str += `${data[i][item] + '\t'},`
				}
				str += '\n'
			}
			//encodeURIComponent解决中文乱码
			let uri =
				'data:text/xls;charset=utf-8,\ufeff' + encodeURIComponent(str)
			//通过创建a标签实现
			let link = document.createElement('a')
			link.href = uri
			//对下载的文件命名
			link.download = `${fileName || '表格数据'}.xls`
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},
		// 修改
		handleEdit(index, course) {
            this.$store.commit('updateCourse', course)
			this.isAdd = false // 修改课程
			this.showForm = true
		},
		// 复制
		async handleCopy(index, course) {
			let res = await copyCourse_api({
				courseId: course.courseInfo.id,
			})
			if (res.code == 200) {
				this.releaseCourseList()
			}
		},
		// 删除
		handleDelete(index, course) {
			this.$confirm('此操作将永久删除该课程, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(async () => {
					let { code, msg } = await removeCourse_api({
						ids: course.courseInfo.id,
					})
					if (code == 200) {
						this.$message({
							type: 'success',
							message: '删除成功!',
						})
						this.releaseCourseList()
					} else {
						this.$message.error(msg)
					}
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
					})
				})
		},
		// 提交审核
		async handleSubmit(index, course) {
			let { code, msg } = await reviewCourse_api({
				courseId: course.courseInfo.id,
			})
			if (code == 200) {
				this.$message({
					type: 'success',
					message: '审核提交成功!',
				})
				this.releaseCourseList()
			} else {
				this.$message.error(msg)
			}
		},
		// 下线
		handleOffline(index, course) {

			this.$confirm('此操作将下线该课程, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(async () => {
					let { code, msg } = await offlineCourse_api({
						courseId: course.courseInfo.id,
					})
					if (code == 200) {
						this.$message({
							type: 'success',
							message: '课程下线成功!',
						})
						this.releaseCourseList()
					} else {
						this.$message.error(msg)
					}
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消下线',
					})
				})
		},
	},
	created() {
		this.releaseCourseList()
		this.getTeacherList()
		this.getCourseSiteList()
		this.getCourseDictionaryList()
		// console.log(this.$store.state.courseStateDict)
	},
}
</script>

<style lang="less" scoped>
.add-card {
	.demo-table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
		&.courseDetail,
		&.courseTimeList {
			/deep/.el-form-item__content {
				width: 100%;
				.ql-editor {
					/deep/.ql-align-center {
						text-align: center;
					}
					img {
						max-width: 100%;
					}
				}
			}
		}
	}
	.pages {
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}
	.el-dropdown-link {
		cursor: pointer;
		color: #409eff;
	}
}
</style>
