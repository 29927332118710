<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span v-if="isAdd">新增课程</span>
        <span v-else></span>
        <el-button @click="changeShowForm" style="float: right; padding: 3px 0" type="text">
          返回发布课程列表
        </el-button>
      </div>
      <el-form size="mini" :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <el-form-item class="inpLimit" label="课程名称" prop="courseInfo.courseName">
          <el-input
                  v-model="ruleForm.courseInfo.courseName"
          ></el-input>
        </el-form-item>
        <el-form-item
                class="inpLimit"
                label="所属场景"
                prop="courseInfo.courseSite"
        >
          <el-select
                  v-model="ruleForm.courseInfo.courseSite"
                  filterable
                  placeholder="请选择场景"
          >
            <el-option
                    v-for="(item, courseSiteIndex) in courseSiteList"
                    :key="courseSiteIndex"
                    :label="item.siteName"
                    :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
                class="inpLimit"
                label="容纳人数"
                prop="courseRule.selectMaxNumber"
        >
          <el-input
                  v-model="ruleForm.courseRule.selectMaxNumber"
          ></el-input>
        </el-form-item>
        <el-form-item
                class="inpLimit"
                        label="课程分类"
                        prop="courseInfo.courseLabel"
                >
                    <el-select
                            v-model="ruleForm.courseInfo.courseLabel"
                            multiple
                            placeholder="请选择课程分类(可多选)"
                    >
                        <el-option
                                v-for="(item,
							courseDictionaryIndex) in courseDictionaryList"
                                :key="courseDictionaryIndex"
                                :label="item.dictValue"
                                :value="item.dictKey"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        class="inpLimit"
                        label="面向人群"
                        prop="courseInfo.courseLimitGrade"
                >
                    <el-select
                            multiple
                            v-model="ruleForm.courseInfo.courseLimitGrade"
                            placeholder="请选择学生年级(可多选)"
                    >
                        <el-option
                                v-for="(item,
							courseLimitGradeIndex) in courseLimitGradeArr"
                                :key="courseLimitGradeIndex"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        class="inpLimit"
                        label="授课老师"
                        prop="courseInfo.courseTeacher"
                >
                    <el-select
                            v-model="ruleForm.courseInfo.courseTeacher"
                            filterable
                            placeholder="请选择授课教师"
                    >
                        <el-option
                                v-for="item in teacherList"
                                :key="item.id"
                                :label="item.teacherName"
                                :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        class="inpLimit"
                        label="上课教室"
                        prop="courseInfo.courseRoom"
                >
                    <el-input
                            v-model="ruleForm.courseInfo.courseRoom"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        class="inpLimit"
                        label="联系电话"
                        prop="courseInfo.phoneNumber"
                >
                    <el-input
                            placeholder="请输入课程联系人电话"
                            v-model="ruleForm.courseInfo.phoneNumber"
                    ></el-input>
                </el-form-item>

                <!-- <el-form-item
                  class="inpLimit"
                  style="width: 100%"
                  label="是否为独立课程"
                  prop="resource"
                >
                  <el-radio-group v-model="ruleForm.courseManageJson.resource">
                    <el-radio label="是"></el-radio>
                    <el-radio label="否"></el-radio>
                  </el-radio-group>
                  <span :style="{ paddingLeft: '20px', color: '#d00' }"
                    >独立课程:一节课为独立的一节课，连续课程:需要连续上几次课才完成学习的选择否</span
                  >
                </el-form-item> -->
                <el-form-item
                        class="inpLimit"
                        style="width: 100%"
                        label="开课日期"
                        prop="courseTime.courseTimeType"
                >
                    <el-radio-group
                            v-model="ruleForm.courseTime.courseTimeType"
                    >
                        <el-radio label="1">专题课</el-radio>
                        <el-radio label="2">体验课</el-radio>
                    </el-radio-group>
                    <span :style="{ paddingLeft: '20px', color: '#d00' }"
                    >专题课:一段时间内每周上课时间固定，体验课:单次的上课时间</span
                    >
                </el-form-item>
                <div v-if="ruleForm.courseTime.courseTimeType == '2'">
                    <el-form-item
                            v-for="(item, index) in ruleForm.courseTime.roundsArr"
                            :key="index"
                            :style="{
							width: '100%',
							borderBottom: '1px solid #eee',
							paddingTop: '10px',
							marginBottom: '0',
						}"
                            :label="'体验选课'"
                            required

                    >
                        <!--						:label="'第' + (index + 1) + '轮'"-->
                        <div>
                            <el-row v-for="(val, i) in item.courseTimeList" :key="i">
                                <el-col :span="7">
                                    <el-form-item
                                            :prop="
											`courseTime.roundsArr[${index}].courseTimeList[${i}].date`
										"
                                            :rules="[
											{
												required: true,
												message: '请选择日期',
												trigger: 'change',
											},
											{
												validator: validateRoundsArr,
												trigger: 'change',
											},
										]"
                                    >
                                        <el-date-picker
                                                type="date"
                                                placeholder="选择日期"
                                                v-model="val.date"
                                                :picker-options="pickerOptions"
                                                style="width: 96%"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="14">
                                    <el-form-item
                                            :prop="
											`courseTime.roundsArr[${index}].courseTimeList[${i}].startTime`
										"
                                            :rules="[
											{
												validator: validateStartTime,
												trigger: ['change', 'blur'],
											},
										]"
                                            style="width: 44%"
                                    >
                                        <el-time-picker
                                                style="width: 100%"
                                                :default-value="1618880400000"
                                                v-model="val.startTime"
                                                :picker-options="{
												selectableRange:
													'07:00:00 - 18:00:00',
											}"
                                                placeholder="起始时间"
                                        >
                                        </el-time-picker>
                                    </el-form-item>
                                    <span style="margin: 0 2px 0 -5px">-</span>
                                    <el-form-item
                                            :prop="
											`courseTime.roundsArr[${index}].courseTimeList[${i}].endTime`
										"
                                            :rules="[
											{
												validator: validateEndTime,
												trigger: ['change', 'blur'],
											},
										]"
                                            style="width: 44%"
                                    >
                                        <el-time-picker
                                                style="width: 100%"
                                                :default-value="1618884000000"
                                                v-model="val.endTime"
                                                :picker-options="{
												selectableRange:
													'07:00:00 - 18:00:00',
											}"
                                                placeholder="结束时间"
                                        >
                                        </el-time-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3">
                                    <!--              <el-button
                                                      v-if="
                                                                            i == item.courseTimeList.length - 1
                                                                        "
                                                      class="addTeacherBtn"
                                                      @click="addCourseTime(1, index, i)"
                                                      type="primary"
                                                      icon="el-icon-plus"
                                                  ></el-button>-->
                                    <el-button
                                            v-if="item.courseTimeList.length > 1"
                                            class="addTeacherBtn"
                                            @click="addCourseTime(-1, index, i)"
                                            type="danger"
                                            plain
                                            icon="el-icon-minus"
                                    ></el-button>
                                </el-col>
                            </el-row>
                         <!--   <el-button
                                    v-if="ruleForm.courseTime.roundsArr.length > 1"
                                    style="margin-bottom: 10px"
                                    @click="delRounds(-1, index)"
                                    size="mini"
                                    type="danger"
                            >删除本轮次
                            </el-button>-->
                        </div>
                    </el-form-item>
                    <!--<el-button
                            @click="delRounds(1)"
                            style="margin: 10px 0 20px 40px"
                            size="mini"
                            type="primary"
                    >增加轮次
                    </el-button>-->
                </div>
                <el-form-item
                        v-else
                        style="width: 100%"
                        label="专题选课"
                        required
                >
                    <el-form-item
                            style="display: inline-block; width: auto"
                            prop="courseTime.circulation.date"
                            :rules="[
							{
								required: true,
								message: '请选择日期',
								trigger: 'change',
							},
							{
								validator: validateCirculationArr,
								trigger: 'change',
							},
						]"
                    >
                        <el-date-picker
                                style="width: 300px"
                                v-model="ruleForm.courseTime.circulation.date"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item
                            style="display: inline-block; width: auto"
                            prop="courseTime.circulation.dayOfWeek"
                    >
                        <el-select
                                style="width: 90px"
                                v-model="ruleForm.courseTime.circulation.dayOfWeek"
                                placeholder="请选择一天"
                        >
                            <el-option
                                    v-for="(dayOfWeek,
								dayOfWeeki) in dayOfWeekOptions"
                                    :key="dayOfWeeki"
                                    :label="dayOfWeek.label"
                                    :value="dayOfWeek.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <div style="display: inline-block; width: auto">
                        <el-form-item
                                prop="courseTime.circulation.startTime"
                                style="display: inline-block; width: auto"
                                :rules="[
								{
									validator: validateCirculationStartTime,
									trigger: ['change', 'blur'],
								},
							]"
                        >
                            <el-time-picker
                                    :default-value="1618880400000"
                                    :style="{
									display: 'inline-block',
									width: '180px',
								}"
                                    :editable="true"
                                    v-model="
									ruleForm.courseTime.circulation.startTime
								"
                                    :picker-options="{
									selectableRange: '07:00:00 - 18:00:00',
								}"
                                    placeholder="起始时间"
                            >
                            </el-time-picker>
                        </el-form-item>
                        <span style="margin-right: 8px">-</span>
                        <el-form-item
                                prop="courseTime.circulation.endTime"
                                style="display: inline-block; width: auto"
                                :rules="[
								{
									validator: validateCirculationEndTime,
									trigger: ['change', 'blur'],
								},
							]"
                        >
                            <el-time-picker
                                    :default-value="1618884000000"
                                    :style="{
									display: 'inline-block',
									width: '180px',
								}"
                                    :editable="true"
                                    v-model="
									ruleForm.courseTime.circulation.endTime
								"
                                    :picker-options="{
									selectableRange: '07:00:00 - 18:00:00',
								}"
                                    placeholder="结束时间"
                            >
                            </el-time-picker>
                        </el-form-item>
                    </div>
                </el-form-item>
                <!-- 教案上传 -->
                <el-form-item
                        prop="courseInfo.lessonPlan"
                        style="width: 100%"
                        label="上传课程教案"
                >
                    <el-upload
                            class="upload-demo"
                            :limit="1"
                            :action="serverUrl"
                            :headers="headers"
                            :on-success="lessonPlanSuccess"
                            :on-remove="removePlanUpload"
                            :on-exceed="exceedLimit"
                            :file-list="lessonPlanList"
                    >
                        <el-button size="mini" type="primary"
                        >点击上传
                        </el-button
                        >
                    </el-upload>
                </el-form-item>
                <el-form-item
                        prop="courseInfo.coursePicture"
                        style="width: 100%"
                        label="上传课程封面"
                >
                    <el-upload
                            class="avatar-uploader"
                            :action="serverUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                    >
                        <img
                                v-if="imageUrl"
                                :src="imageUrl"
                                fit="cover"
                                class="avatar"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item
                        style="width: 100%"
                        label="课程简介"
                        prop="courseInfo.courseIntroduction"
                >
                    <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入课程简介"
                            v-model="ruleForm.courseInfo.courseIntroduction"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                        style="width: 100%"
                        label="课程详情"
                        prop="courseInfo.courseDetail"
                >
                    <!-- 图片上传组件辅助-->
                    <div class="quillEditor">
                        <el-upload
                                id="file"
                                class="quillEditor-avatar-uploader"
                                :action="serverUrl"
                                name="file"
                                :headers="headers"
                                :show-file-list="false"
                                :on-success="uploadSuccess"
                                :on-error="uploadError"
                                :before-upload="beforeUpload"
                        >
                        </el-upload>
                    </div>
                    <!--富文本编辑器组件-->
                    <el-row v-loading="quillUpdateImg">
                        <quillEditor
                                v-model="ruleForm.courseInfo.courseDetail"
                                ref="myQuillEditor"
                                :options="editorOption"
                                @change="onEditorChange($event)"
                                @ready="onEditorReady($event)"
                        >
                        </quillEditor>
                    </el-row>
                </el-form-item>
                <el-form-item style="margin-left: 130px">
                    <el-button type="primary" @click="submitForm('ruleForm')">{{
                        isAdd ? '立即创建' : '立即修改'
                        }}
                    </el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    import {quillEditor} from 'vue-quill-editor'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import moment from 'moment'
    import {sessionGet} from '@/utils/local.js'
    import {ADDCOURSE} from '@/utils/reg.js'
    import {addCourse_api, updateCourse_api} from '@/api/user.js'
    import loginVue from '../../../login/login.vue'

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [{header: 1}, {header: 2}], // custom button values
        [{list: 'ordered'}, {list: 'bullet'}],
        [{script: 'sub'}, {script: 'super'}], // superscript/subscript
        [{indent: '-1'}, {indent: '+1'}], // outdent/indent
        [{direction: 'rtl'}], // text direction

        [{size: ['small', false, 'large', 'huge']}], // custom dropdown
        [{header: [1, 2, 3, 4, 5, 6, false]}],

        [{color: []}, {background: []}], // dropdown with defaults from theme
        [{font: []}],
        [{align: []}],
        ['link', 'image', 'video'],
        ['clean'], // remove formatting button
    ]
    export default {
        components: {
            quillEditor,
        },
        props: ['teacherList', 'courseSiteList', 'courseDictionaryList', 'isAdd'],
        data() {
            // 验证容纳人数
            var validateselectMaxNumber = (rule, value, callback) => {
                if (!ADDCOURSE.NUM_REG.test(value)) {
                    callback(new Error('请输入正整数值'))
                } else if (value > 200) {
                    callback(new Error('最大可输入200人'))
                } else {
                    callback()
                }
            }
            // 手机号码验证
            var validateCourseContactPhone = (rule, value, callback) => {
                if (!value) return callback()
                if (!ADDCOURSE.telStr.test(value)) {
                    callback(new Error('请输入规范的手机号'))
                } else {
                    callback()
                }
            }

            return {
                imageUrl: '', // 课程封面图片回显地址
                lessonPlanList: [], // 课程教案列表
                headers: {
                    // 上传图片的请求头
                    Authorization: 'Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=', // 网站标识‘portal’的base64编码后字符串
                    'Tenant-Id': '501654',
                    'Blade-Auth':
                        sessionGet('tk').token_type +
                        ' ' +
                        sessionGet('tk').access_token,
                },
                quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
                serverUrl: '/api/blade-resource/oss/endpoint/put-file', // 这里写你要上传的图片服务器地址
                editorOption: {
                    // 富文本编辑器配置
                    placeholder: '',
                    theme: 'snow', // or 'bubble'
                    modules: {
                        toolbar: {
                            container: toolbarOptions, // 工具栏
                            handlers: {
                                image: function (value) {
                                    if (value) {
                                        // 触发input框选择图片文件
                                        document
                                            .querySelector(
                                                '.quillEditor .quillEditor-avatar-uploader input'
                                            )
                                            .click()
                                    } else {
                                        this.quill.format('image', false)
                                    }
                                },
                            },
                        },
                    },
                },
                isDisabled: false, // 是否禁用提交按钮
                // 轮次选择日期时间验证的临时变量
                valiRounds: {
                    preRoundsDate: '', // 前一轮次最后一个课时的日期
                    nextRoundsDate: '', // 后一轮次第一个课时的日期
                    index: '', // 轮次索引
                    classTimeIndex: '', // 课时索引
                    length: '', // 课时数组的长度
                    date: '', // 当前课时的日期
                    endTime: '', // 当前课时的结束时间
                    startTime: '', // 当前课时的开始时间
                    preDate: '', // 前一课时的日期
                    preEndTime: '', // 前一课时的结束时间
                    nextDate: '', // 后一课时的日期
                    nextEndTime: '', // 后一课时的结束时间
                },
                courseLimitGradeArr: [
                    {value: '1', label: '一年级'},
                    {value: '2', label: '二年级'},
                    {value: '3', label: '三年级'},
                    {value: '4', label: '四年级'},
                    {value: '5', label: '五年级'},
                    {value: '6', label: '六年级'},
                    {value: '7', label: '初一'},
                    {value: '8', label: '初二'},
                    {value: '9', label: '初三'},
                ],
                dayOfWeekOptions: [
                    // 选择周几上课
                    {value: 1, label: '每周一'},
                    {value: 2, label: '每周二'},
                    {value: 3, label: '每周三'},
                    {value: 4, label: '每周四'},
                    {value: 5, label: '每周五'},
                    {value: 6, label: '每周六'},
                    {value: 7, label: '每周天'},
                ],
                pickerOptions: {
                    // 只能选24小时之后的日期
                    disabledDate(time) {
                        // return time.getTime() < Date.now() + 8.64e7;
                        return time.getTime() < Date.now() - 8.64e7
                    },
                },
                ruleForm: {
                    courseInfo: {
                        courseLabel: [], // 课程分类
                        courseTeacher: '', // 授课老师
                        courseSite: '', // 开课场景
                        courseRoom: '', // 上课教室
                        lessonPlan: '', // 课程教案
                        //   lessonPlanName: "", // 课程教案名称
                        coursePicture: '', // 课程封面图片
                        courseIntroduction: '', // 课程简介
                        courseDetail: '', // 富文本内容
                        courseName: '', // 课程名称
                        phoneNumber: '', // 联系电话
                        //   courseContactPerson: "", // 课程联系人
                        //   courseContactPhone: "", // 联系电话
                        courseLimitGrade: [], // 课程限制年级
                        //   selectMaxNumber: "" // 容纳人数（最大限制人数）
                    },
                    courseTime: {
                        courseTimeType: '1', // 2表示自定义轮次，1表示循环
                        roundsArr: [
                            // 轮次日期
                            {
                                // 第一轮次，轮次用户可以动态增加，最多6轮
                                courseTimeList: [
                                    // 第一轮次，第一个时间，每轮最多6个时间，用户动态添加
                                    {date: '', startTime: '', endTime: ''},
                                ],
                            },
                        ],
                        circulation: {
                            // 循环上课
                            date: '', // 日期段
                            dayOfWeek: 6, // 星期六
                            startTime: '',
                            endTime: '',
                        },
                    },
                    courseRule: {
                        selectMaxNumber: '', // 容纳人数（最大限制人数）
                    },
                    courseInfoTime: [
                        {
                            timeType: '1', //课程时间类型，1循环，2自定义轮次
                            round: 0, //轮次
                            session: 0, //课次
                            date: '', //上课时间
                            startTime: '', //开始时间
                            endTime: '', //结束时间
                            dayOfWeek: 6, //周几上课
                            startdayOfWeek: '', //循坏开始时间
                            enddayOfWeek: '', //循环结束时间
                        },
                    ],
                    // courseRule: {
                    //   selectMaxNumber: "", // 容纳人数（最大限制人数）
                    // },
                    // courseManageJson: {
                    //   courseTimeType: "1", // 2表示自定义轮次，1表示循环
                    //   roundsArr: [
                    //     // 轮次日期
                    //     {
                    //       // 第一轮次，轮次用户可以动态增加，最多6轮
                    //       courseTimeList: [
                    //         // 第一轮次，第一个时间，每轮最多6个时间，用户动态添加
                    //         { date: "", startTime: "", endTime: "" },
                    //       ],
                    //     },
                    //   ],
                    //   circulation: {
                    //     // 循环上课
                    //     date: "", // 日期段
                    //     dayOfWeek: 6, // 星期六
                    //     startTime: "",
                    //     endTime: "",
                    //   },
                    //   courseLabel: [], // 课程分类
                    //   courseTeacher: "", // 授课老师
                    //   courseSite: "", // 开课场景
                    //   courseRoom: "", // 上课教室
                    //   lessonPlan: "", // 课程教案
                    //   lessonPlanName:"", // 课程教案名称
                    //   coursePicture: "", // 课程封面图片
                    //   courseIntroduction: "", // 课程简介
                    //   courseDetail: "", // 富文本内容
                    //   courseName: "", // 课程名称
                    //   courseContactPerson: "", // 课程联系人
                    //   courseContactPhone: "", // 联系电话
                    //   courseLimitGrade: [], // 课程限制年级
                    // },
                },
                rules: {
                    'courseRule.selectMaxNumber': [
                        {
                            required: true,
                            message: '请输入课程容纳人数',
                            trigger: 'blur',
                        },
                        {validator: validateselectMaxNumber, trigger: 'blur'},
                    ],
                    'courseInfo.courseName': [
                        {
                            required: true,
                            message: '请输入课程名称',
                            trigger: 'blur',
                        },
                        {
                            min: 2,
                            max: 16,
                            message: '长度在 2 到 16 个字符',
                            trigger: 'blur',
                        },
                    ],
                    'courseInfo.courseSite': [
                        {
                            required: true,
                            message: '请选择课程场景',
                            trigger: 'change',
                        },
                    ],
                    'courseInfo.courseLabel': [
                        {
                            required: true,
                            message: '请选择课程分类',
                            trigger: 'change',
                        },
                    ],
                    'courseInfo.courseLimitGrade': [
                        {
                            required: true,
                            message: '请选择面向人群',
                            trigger: 'change',
                        },
                    ],
                    'courseInfo.courseTeacher': [
                        {
                            required: true,
                            message: '请选择授课教师',
                            trigger: 'change',
                        },
                    ],
                    'courseInfo.courseRoom': [
                        {
                            required: true,
                            message: '请输入上课教室',
                            trigger: 'blur',
                        },
                    ],
                    'courseInfo.phoneNumber': [
                        {
                            required: true,
                            message: '请输入联系电话',
                            trigger: 'blur',
                        },
                    ],
                    // 'courseManageJson.courseContactPerson': [
                    // 	{
                    // 		min: 2,
                    // 		max: 6,
                    // 		message: '长度在 2 到 6 个字符',
                    // 		trigger: 'blur',
                    // 	},
                    // 	{ message: '请输入课程联系人', trigger: 'blur' },
                    // ],
                    // 'courseManageJson.courseContactPhone': [
                    // 	{
                    // 		validator: validateCourseContactPhone,
                    // 		trigger: 'blur',
                    // 	},
                    // ],
                    'courseTime.courseTimeType': [
                        {
                            required: true,
                            message: '请选择开课日期类型',
                            trigger: 'change',
                        },
                    ],
                    'courseTime.circulation.dayOfWeek': [
                        {
                            required: true,
                            message: '请选择周几',
                            trigger: 'change',
                        },
                    ],
                    'courseInfo.coursePicture': [
                        {required: true, message: '请上传图片'},
                    ],
                    // 上传教案
                    'courseInfo.lessonPlan': [
                        // { required: true, message: '请上传课程教案' },
                    ],

                    'courseInfo.courseIntroduction': [
                        {
                            required: true,
                            message: '请输入课程简介',
                            trigger: 'blur',
                        },
                        {
                            min: 0,
                            max: 120,
                            message: '课程简介最多120个字符',
                            trigger: 'blur',
                        },
                    ],
                    'courseInfo.courseDetail': [
                        {
                            required: true,
                            message: '请输入课程详情',
                        },
                    ],
                },
            }
        },
        methods: {
            // 自定义验证循环选课日期
            validateCirculationArr(rule, value, callback) {
                if (
                    new Date(value[1]).getTime() - new Date(value[0]).getTime() <
                    6 * 8.64e7
                ) {
                    // 去掉当天后跨度小于6天
                    callback(new Error('专题日期跨度不能小于一周'))
                } else {
                    callback()
                }
            },
            // 自定义验证循环选课开始时间在结束时间之前
            validateCirculationStartTime(rule, value, callback) {
                if (!value) {
                    callback(new Error('请选择开始时间'))
                } else if (!this.ruleForm.courseTime.circulation.endTime) {
                    callback()
                } else if (
                    this.ruleForm.courseTime.circulation.endTime.getTime() -
                    value.getTime() <=
                    0
                ) {
                    callback(new Error('开始时间应小于结束时间'))
                } else {
                    callback()
                }
            },
            // 自定义验证循环选课结束时间在开始时间之后
            validateCirculationEndTime(rule, value, callback) {
                if (!value) {
                    callback(new Error('请选择结束时间'))
                } else if (!this.ruleForm.courseTime.circulation.startTime) {
                    callback()
                } else if (
                    this.ruleForm.courseTime.circulation.startTime.getTime() -
                    value.getTime() >=
                    0
                ) {
                    callback(new Error('结束时间应大于开始时间'))
                } else {
                    callback()
                }
            },
            // 处理轮次课程日期时间选择的验证临时变量
            updatevaliRounds(rule) {
                // 先重置
                this.valiRounds = {
                    preRoundsDate: '', // 前一轮次最后一个课时的日期
                    nextRoundsDate: '', // 后一轮次第一个课时的日期
                    index: '', // 轮次索引
                    classTimeIndex: '', // 课时索引
                    length: '', // 课时数组的长度
                    date: '', // 当前课时的日期
                    preDate: '', // 前一课时的日期
                    preEndTime: '', // 前一课时的结束时间
                    nextDate: '', // 后一课时的日期
                    nextEndTime: '', // 后一课时的结束时间
                }
                // 再赋值
                this.valiRounds.index = Number(
                    // 轮次索引
                    rule.fullField.substring(
                        rule.fullField.indexOf('[') + 1,
                        rule.fullField.indexOf(']')
                    )
                )
                this.valiRounds.classTimeIndex = Number(
                    // 课时索引
                    rule.fullField.substring(
                        rule.fullField.lastIndexOf('[') + 1,
                        rule.fullField.lastIndexOf(']')
                    )
                )
                if (this.valiRounds.index > 0) {
                    // 当前轮次非第一轮次
                    // 前一轮次的课时数组
                    let courseTimeList = this.ruleForm.courseTime.roundsArr[
                    this.valiRounds.index - 1
                        ].courseTimeList
                    // 前一轮次课时数组的长度
                    let length = courseTimeList.length
                    // 前一轮次最后一个课时的日期
                    this.valiRounds.preRoundsDate = new Date(
                        courseTimeList[length - 1].date
                    )
                }
                if (
                    this.valiRounds.index <
                    this.ruleForm.courseTime.roundsArr.length - 1
                ) {
                    // 当前轮次非最后一个轮次
                    // 后一轮课时数组
                    let nextcourseTimeList = this.ruleForm.courseTime.roundsArr[
                    this.valiRounds.index + 1
                        ].courseTimeList
                    // 后一轮次第一个课时的日期
                    this.valiRounds.nextRoundsDate = new Date(
                        nextcourseTimeList[0].date
                    )
                }
                // 当前轮次课时数组的长度
                this.valiRounds.length = this.ruleForm.courseTime.roundsArr[
                    this.valiRounds.index
                    ].courseTimeList.length
                if (
                    this.ruleForm.courseTime.roundsArr[this.valiRounds.index]
                        .courseTimeList[this.valiRounds.classTimeIndex]
                ) {
                    // 当前课时日期
                    this.valiRounds.date = new Date(
                        this.ruleForm.courseTime.roundsArr[
                            this.valiRounds.index
                            ].courseTimeList[this.valiRounds.classTimeIndex].date
                    )
                    // 当前课时结束时间
                    this.valiRounds.endTime = moment(
                        this.ruleForm.courseTime.roundsArr[this.valiRounds.index]
                            .courseTimeList[this.valiRounds.classTimeIndex].endTime
                    ).format('HH:mm:ss')
                    // 当前课时开始时间
                    this.valiRounds.startTime = moment(
                        this.ruleForm.courseTime.roundsArr[this.valiRounds.index]
                            .courseTimeList[this.valiRounds.classTimeIndex]
                            .startTime
                    ).format('HH:mm:ss')
                }
                if (
                    this.ruleForm.courseTime.roundsArr[this.valiRounds.index]
                        .courseTimeList[this.valiRounds.classTimeIndex - 1]
                ) {
                    // 前一课时的日期
                    this.valiRounds.preDate = new Date(
                        this.ruleForm.courseTime.roundsArr[
                            this.valiRounds.index
                            ].courseTimeList[this.valiRounds.classTimeIndex - 1].date
                    )
                    // 前一课时的结束时间
                    this.valiRounds.preEndTime = moment(
                        this.ruleForm.courseTime.roundsArr[this.valiRounds.index]
                            .courseTimeList[this.valiRounds.classTimeIndex - 1]
                            .endTime
                    ).format('HH:mm:ss')
                }
                if (
                    this.ruleForm.courseTime.roundsArr[this.valiRounds.index]
                        .courseTimeList[this.valiRounds.classTimeIndex + 1]
                ) {
                    // 后一课时的日期
                    this.valiRounds.nextDate = new Date(
                        this.ruleForm.courseTime.roundsArr[
                            this.valiRounds.index
                            ].courseTimeList[this.valiRounds.classTimeIndex + 1].date
                    )
                    // 后一课时的开始时间
                    this.valiRounds.nextStartTime = moment(
                        this.ruleForm.courseTime.roundsArr[this.valiRounds.index]
                            .courseTimeList[this.valiRounds.classTimeIndex + 1]
                            .startTime
                    ).format('HH:mm:ss')
                }
            },
            // 自定义验证轮次的结束时间
            validateEndTime(rule, value, callback) {
                this.updatevaliRounds(rule)
                if (!value) {
                    callback(new Error('请选择结束时间'))
                } else if (
                    this.valiRounds.endTime &&
                    new Date('2021-4-20 ' + this.valiRounds.startTime).getTime() -
                    new Date(
                        '2021-4-20 ' + this.valiRounds.endTime
                    ).getTime() >=
                    0
                ) {
                    callback(new Error('开始时间应小于结束时间'))
                } else if (
                    this.valiRounds.startTime &&
                    new Date('2021-4-20 ' + this.valiRounds.startTime).getTime() -
                    new Date(
                        '2021-4-20 ' + this.valiRounds.endTime
                    ).getTime() >=
                    0
                ) {
                    callback(new Error('结束时间应大于开始时间'))
                } else if (
                    this.valiRounds.date &&
                    this.valiRounds.nextDate &&
                    this.valiRounds.date.getTime() ===
                    this.valiRounds.nextDate.getTime()
                ) {
                    // 当前课时日期等于前一课时日期
                    let endTime = new Date(
                        moment(this.valiRounds.date).format('yyyy-MM-DD') +
                        ' ' +
                        this.valiRounds.endTime
                    )
                    let nextDateStartTime = new Date(
                        moment(this.valiRounds.nextDate).format('yyyy-MM-DD') +
                        ' ' +
                        this.valiRounds.nextStartTime
                    )
                    if (
                        isNaN(nextDateStartTime.getTime()) ||
                        endTime.getTime() < nextDateStartTime.getTime()
                    ) {
                        callback()
                    } else {
                        callback(new Error('时间与后一课时时间冲突'))
                    }
                } else {
                    callback()
                }
            },
            // 自定义验证轮次的起始时间
            validateStartTime(rule, value, callback) {
                this.updatevaliRounds(rule)
                if (!value) {
                    callback(new Error('请选择开始时间'))
                } else if (
                    this.valiRounds.endTime &&
                    new Date('2021-4-20 ' + this.valiRounds.startTime).getTime() -
                    new Date(
                        '2021-4-20 ' + this.valiRounds.endTime
                    ).getTime() >=
                    0
                ) {
                    callback(new Error('开始时间应小于结束时间'))
                } else if (
                    this.valiRounds.date &&
                    this.valiRounds.preDate &&
                    this.valiRounds.date.getTime() ===
                    this.valiRounds.preDate.getTime()
                ) {
                    // 当前课时日期等于前一课时日期
                    let startTime = new Date(
                        moment(this.valiRounds.date).format('yyyy-MM-DD') +
                        ' ' +
                        this.valiRounds.startTime
                    )
                    let preDateEndTime = new Date(
                        moment(this.valiRounds.preDate).format('yyyy-MM-DD') +
                        ' ' +
                        this.valiRounds.preEndTime
                    )
                    if (
                        isNaN(preDateEndTime) ||
                        startTime.getTime() > preDateEndTime.getTime()
                    ) {
                        callback()
                    } else {
                        callback(new Error('时间与前一课时时间冲突'))
                    }
                } else {
                    callback()
                }
            },
            // 自定义轮次日期选择器验证
            validateRoundsArr(rule, value, callback) {
                this.updatevaliRounds(rule)
                value = new Date(value) // 解决回显后不修改直接提交，验证问题
                if (
                    this.valiRounds.index > 0 &&
                    this.valiRounds.preRoundsDate &&
                    value.getTime() <= this.valiRounds.preRoundsDate.getTime()
                ) {
                    // 不是第一个轮次，判断该轮次第一个日期是否大于上一个轮次的最后一个日期
                    return callback(new Error('本轮次日期应大于上轮次日期'))
                }
                if (
                    this.valiRounds.index <
                    this.ruleForm.courseTime.roundsArr.length - 1 &&
                    this.valiRounds.nextRoundsDate &&
                    value.getTime() >= this.valiRounds.nextRoundsDate.getTime()
                ) {
                    // 不是最后一个轮次，判断该轮次最后一个日期是否小于下一个轮次的第一个日期
                    return callback(new Error('本轮次日期应小于下轮次日期'))
                }

                if (this.valiRounds.classTimeIndex === 0) {
                    // 第一个课时
                    if (
                        this.valiRounds.classTimeIndex ===
                        this.valiRounds.length - 1
                    ) {
                        // 并且最后一个课时
                        callback()
                    } else {
                        // 不是最后一个，判断与后一个日期的大小
                        if (
                            this.valiRounds.nextDate &&
                            value.getTime() > this.valiRounds.nextDate.getTime()
                        ) {
                            callback(new Error('当前日期应小于等于后一个日期'))
                        } else {
                            callback()
                        }
                    }
                } else if (
                    // 最后一个课时
                    this.valiRounds.classTimeIndex ===
                    this.valiRounds.length - 1
                ) {
                    if (
                        // 判断与前一个日期的大小
                        this.valiRounds.preDate &&
                        value.getTime() < this.valiRounds.preDate.getTime()
                    ) {
                        callback(new Error('当前日期应大于等于前一个日期'))
                    } else {
                        callback()
                    }
                } else {
                    // 中间课时
                    if (
                        // 判断与前一个日期的大小
                        this.valiRounds.preDate &&
                        value.getTime() < this.valiRounds.preDate.getTime()
                    ) {
                        callback(new Error('当前日期应大于等于前一个日期'))
                    } else if (
                        // 判断与后一个日期的大小
                        this.valiRounds.nextDate &&
                        value.getTime() > this.valiRounds.nextDate.getTime()
                    ) {
                        callback(new Error('当前日期应小于等于后一个日期'))
                    } else {
                        callback()
                    }
                }
            },
            // 显示发布列表
            changeShowForm() {
                this.$emit('changeShowForm', false)
            },
            // 增加/删除自定义轮次里面的时间
            addCourseTime(num, index, i) {
                // num为1表示增加-1表示删除，index对应某个轮次的索引,i对应轮次里面时间的索引
                // console.log(num, index, i)
                if (num > 0) {
                    // 增加
                    if (
                        this.ruleForm.courseTime.roundsArr[index].courseTimeList
                            .length == 6
                    )
                        return this.$message.error('每个轮次最多能创建6个时间')
                    this.ruleForm.courseTime.roundsArr[index].courseTimeList.push({
                        date: '',
                        startTime: '',
                        endTime: '',
                    })
                } else {
                    //删除
                    if (
                        this.ruleForm.courseTime.roundsArr[index].courseTimeList
                            .length > 1
                    )
                        this.ruleForm.courseTime.roundsArr[
                            index
                            ].courseTimeList.splice(i, 1)
                    else this.$message.error('请为该轮次至少创建一个上课时间')
                }
            },
            // 增加/删除轮次
            delRounds(num, index) {
                // num为-1表示删除本轮次，1表示增加一个轮次
                if (num > 0) {
                    // 增加
                    if (this.ruleForm.courseTime.roundsArr.length == 6)
                        return this.$message.error('最多能创建6个轮次')
                    this.ruleForm.courseTime.roundsArr.push({
                        courseTimeList: [{date: '', startTime: '', endTime: ''}],
                    })
                } else {
                    //删除
                    if (this.ruleForm.courseTime.roundsArr.length > 1)
                        this.ruleForm.courseTime.roundsArr.splice(index, 1)
                    else this.$message.error('请至少创建一个体验课')
                }
            },
            // 课程教案超出限制个数
            exceedLimit() {
                this.$message.error('只能上传一个教案')
            },
            // 课程教案上传成功
            lessonPlanSuccess(res) {
                this.ruleForm.courseInfo.lessonPlan = res.data.link
                this.ruleForm.courseInfo.lessonPlanName = res.data.originalName
                this.lessonPlanList.push({
                    name: res.data.originalName,
                    url: res.data.link,
                })
            },
            // 课程教案删除
            removePlanUpload() {
                this.ruleForm.courseInfo.lessonPlan = ''
                this.ruleForm.courseInfo.lessonPlanName = ''
                this.lessonPlanList = []
            },
            // 图片上传成功的回调
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw)
                if (res.code == 200)
                    this.ruleForm.courseInfo.coursePicture = res.data.link
                this.$refs['ruleForm'].validateField('courseInfo.coursePicture') //验证
            },
            // 图片上传前的回调
            beforeAvatarUpload(file) {
                const isJPGPNG =
                    file.type === 'image/jpeg' || file.type === 'image/png'
                const isLt2M = file.size / 1024 / 1024 < 5
                this.headers = {
                    // 上传文件前获取请求头，防止长时间未操作，使用token更新前的token
                    Authorization: 'Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=', // 网站标识‘portal’的base64编码后字符串
                    'Tenant-Id': '501654',
                    'Blade-Auth':
                        sessionGet('tk').token_type +
                        ' ' +
                        sessionGet('tk').access_token,
                }
                if (!isJPGPNG) {
                    this.$message.error('上传图片只能是 JPG/PNG 格式!')
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 5MB!')
                }
                return isJPGPNG && isLt2M
            },
            // 富文本图片上传前
            beforeUpload(file) {
                this.headers = {
                    // 上传文件前获取请求头，防止长时间未操作，使用token更新前的token
                    Authorization: 'Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=', // 网站标识‘portal’的base64编码后字符串
                    'Tenant-Id': '501654',
                    'Blade-Auth':
                        sessionGet('tk').token_type +
                        ' ' +
                        sessionGet('tk').access_token,
                }
                const isLt2M = file.size / 1024 / 1024 < 5
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 5MB!')
                } else {
                    // 显示loading动画
                    this.quillUpdateImg = true
                }
                return isLt2M
            },

            uploadSuccess(res, file) {
                // res为图片服务器返回的数据
                console.log(res)
                // 获取富文本组件实例
                let quill = this.$refs.myQuillEditor.quill
                // 如果上传成功
                console.log(res.code == '200' && res.data && res.data.link)
                if (res.code == '200' && res.data && res.data.link) {
                    // 获取光标所在位置
                    let length = quill.getSelection().index
                    // 插入图片  res.data.link为服务器返回的图片地址
                    quill.insertEmbed(length, 'image', res.data.link)
                    // 调整光标到最后
                    quill.setSelection(length + 1)
                } else {
                    this.$message.error('图片插入失败')
                }
                // loading动画消失
                this.quillUpdateImg = false
            },

            // 富文本图片上传失败
            uploadError() {
                // loading动画消失
                this.quillUpdateImg = false
                this.$message.error('图片插入失败')
            },
            onEditorChange($event) {
            },
            // 富文本
            onEditorReady($event) {
            },
            // 提交新增课程表单
            submitForm(formName) {
                if (this.isDisabled)
                    return this.$message.error('请勿频繁点击,正在请求数据')
                this.isDisabled = true
                setTimeout(() => {
                    this.isDisabled = false
                }, 4000)
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.isAdd) {
                            this.addCourse()
                        } else {
                            this.updateCourse()
                        }
                    } else {
                        this.$message.error('请检查课程信息')
                        // console.log('error submit!!')
                        return false
                    }
                })
            },
            // 数据准备
            isData() {
                let courseInfo = JSON.parse(
                    JSON.stringify(this.ruleForm.courseInfo)
                )
                let courseRule = JSON.parse(
                    JSON.stringify(this.ruleForm.courseRule)
                )
                let courseTime = JSON.parse(
                    JSON.stringify(this.ruleForm.courseTime)
                )
                let courseInfoTime = JSON.parse(
                    JSON.stringify(this.ruleForm.courseInfoTime)
                )
                courseInfoTime = []
                if (courseTime.courseTimeType == '2') {
                    // 是否轮次课时
                    courseTime.courseTimeList = JSON.parse(
                        JSON.stringify(courseTime.roundsArr)
                    )
                    courseTime.courseTimeList.forEach((item) => {
                        item.courseTimeList.forEach((val) => {
                            val.startTime = moment(val.startTime).format('HH:mm:SS')
                            val.endTime = moment(val.endTime).format('HH:mm:SS')
                            val.date = moment(new Date(val.date)).format(
                                'yyyy-MM-DD'
                            )
                        })
                    })
                    //roundsArr赋值给courseInfoTime
                    let i = 0
                    for (let r = 0; r < courseTime.courseTimeList.length; r++) {
                        for (
                            let s = 0;
                            s < courseTime.courseTimeList[r].courseTimeList.length;
                            s++
                        ) {
                            courseInfoTime[i] = Object.assign(
                                {},
                                {
                                    timeType: '2',
                                    startTime:
                                    courseTime.courseTimeList[r].courseTimeList[
                                        s
                                        ].startTime,
                                    endTime:
                                    courseTime.courseTimeList[r].courseTimeList[
                                        s
                                        ].endTime,
                                    round: r + 1,
                                    session: s + 1,
                                    date:
                                    courseTime.courseTimeList[r].courseTimeList[
                                        s
                                        ].date,
                                }
                            )

                            i++
                        }
                    }
                } else {
                    courseTime.courseTimeList = JSON.parse(
                        JSON.stringify(courseTime.circulation)
                    )
                    courseTime.courseTimeList.startTime = moment(
                        courseTime.courseTimeList.startTime
                    ).format('HH:mm:SS')
                    courseTime.courseTimeList.endTime = moment(
                        courseTime.courseTimeList.endTime
                    ).format('HH:mm:SS')
                    courseTime.courseTimeList.date = courseTime.courseTimeList.date.map(
                        (item) => {
                            item = moment(new Date(item)).format('yyyy-MM-DD')
                            return item
                        }
                    )
                    //courseTimeList赋值给courseInfoTime
                    courseInfoTime[0] = Object.assign(
                        {},
                        {
                            timeType: '1',
                            dayOfWeek: courseTime.courseTimeList.dayOfWeek,
                            startTime: courseTime.courseTimeList.startTime,
                            endTime: courseTime.courseTimeList.endTime,
                            startDay: courseTime.courseTimeList.date[0],
                            endDay: courseTime.courseTimeList.date[1],
                        }
                    )
                }
                courseTime.roundsArr = [
                    {
                        // 第一轮次，轮次用户可以动态增加，最多6轮
                        courseTimeList: [
                            // 第一轮次，第一个时间，每轮最多6个时间，用户动态添加
                            {date: '', startTime: '', endTime: ''},
                        ],
                    },
                ]
                courseTime.circulation = {
                    // 循环上课
                    date: '', // 日期段
                    dayOfWeek: 6, // 星期六
                    startTime: '',
                    endTime: '',
                }
                courseInfo.courseLimitGrade.sort((a, b) => {
                    return a - b
                })
                courseInfo.courseLimitGrade = courseInfo.courseLimitGrade.join(',')
                courseInfo.courseLabel = courseInfo.courseLabel.join(',')
                // console.log(courseInfoTime);
                let data = {
                    courseInfo,
                    courseRule,
                    courseInfoTime,
                }
                return data
            },
            // 发送修改请求
            async updateCourse() {
                let data = this.isData()
                let {code} = await updateCourse_api({
                    courseInfo: data.courseInfo,
                    courseRule: data.courseRule,
                    courseInfoTime: data.courseInfoTime,
                })
                if (code === 200) {
                    this.changeShowForm()
                } else {
                    this.$message.error('修改失败')
                }
            },
            // 发送请求（发布课程）
            async addCourse() {
                let data = this.isData()
                let {code} = await addCourse_api({
                    courseInfo: data.courseInfo,
                    courseRule: data.courseRule,
                    courseInfoTime: data.courseInfoTime,
                })
                if (code === 200) {
                    this.changeShowForm()
                }
            },
            // 修改时回显数据
            updateData() {
                if (!this.isAdd) {
                    // 修改
                    // console.log(this.editData)
                    this.imageUrl = this.editData.courseInfo.coursePicture
                    this.lessonPlanList.push({
                        // name:this.editData.lessonPlanName,
                        name: this.editData.courseInfo.lessonPlan.slice(
                            this.editData.courseInfo.lessonPlan.lastIndexOf('/') + 1
                        ),
                        url: this.editData.courseInfo.lessonPlan,
                    })

                    this.ruleForm.courseRule.selectMaxNumber = this.editData.courseRule.selectMaxNumber
                    this.editData.courseTime.courseTimeType = String(
                        this.editData.courseTime.courseTimeType
                    )

                    if (typeof this.editData.courseInfo.courseLabel == 'string')
                        this.editData.courseInfo.courseLabel = this.editData.courseInfo.courseLabel.split(
                            ','
                        )
                    if (
                        typeof this.editData.courseInfo.courseLimitGrade == 'string'
                    )
                        this.editData.courseInfo.courseLimitGrade = this.editData.courseInfo.courseLimitGrade.split(
                            ','
                        )
                    // delete this.editData.courseTimeList

                    this.ruleForm.courseInfo = JSON.parse(
                        JSON.stringify(this.editData.courseInfo)
                    )
                    this.ruleForm.courseTime.circulation.startTime = new Date(
                        new Date().toLocaleDateString() +
                        ' ' +
                        this.editData.courseTime.circulation.startTime
                    )
                    this.ruleForm.courseTime.circulation.endTime = new Date(
                        new Date().toLocaleDateString() +
                        ' ' +
                        this.editData.courseTime.circulation.endTime
                    )
                    this.ruleForm.courseTime.courseTimeType = this.editData.courseTime.courseTimeType
                    this.ruleForm.courseTime.circulation.dayOfWeek = this.editData.courseTime.circulation.day
                    this.ruleForm.courseTime.circulation.date = this.editData.courseTime.circulation.date
                    this.editData.courseTime.roundsArr.forEach((item) => {
                        item.courseTimeList.forEach((val) => {
                            val.startTime = new Date(
                                new Date().toLocaleDateString() +
                                ' ' +
                                val.startTime
                            )
                            val.endTime = new Date(
                                new Date().toLocaleDateString() + ' ' + val.endTime
                            )
                        })
                    })
                    this.ruleForm.courseTime.roundsArr = this.editData.courseTime.roundsArr
                    this.ruleForm.courseInfoTime = this.editData.courseInfoTime
                    // console.log(this.ruleForm)
                }
            },
        },
        computed: {
            editData() {
                // 修改时需要回显的数据
                return this.$store.state.course
            },
        },
        created() {
            this.updateData()
        },
    }
</script>

<style lang="less" scoped>
    .el-card {
        margin-bottom: 30px;

        .el-form--inline .el-form-item {
            width: calc(50% - 10px);

            &.inpLimit {
                /deep/ .el-select {
                    width: 100%;
                }
            }
        }

        /deep/ .el-form-item__content {
            width: calc(100% - 130px);

            .el-form-item--mini {
                width: 100%;

                .el-form-item__content {
                    width: 100%;
                }
            }

            .addTeacherBtn {
                margin-left: 2px;
                padding: 4px 2px;
                font-size: 16px;
                vertical-align: bottom;
            }
        }

        /deep/ .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        /deep/ .avatar-uploader .el-upload:hover {
            border-color: #409eff;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
        }

        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
</style>
